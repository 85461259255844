import React, { useState, useEffect } from "react"
import styles from "./login.module.css"
//import emailStyles from "./loginEmail.module.css" - Need to switch from inline to this later
import { Auth, API, graphqlOperation } from "aws-amplify"
import { navigate } from "gatsby-link"
import { Alert } from "@material-ui/lab"
import { CircularProgress } from "@material-ui/core"
import { IconButton, InputAdornment, TextField } from "@material-ui/core"
import Visibility from "@material-ui/icons/Visibility"
import VisibilityOff from "@material-ui/icons/VisibilityOff"
import { withStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"

const EmailField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#d29723",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#28334a",
      },
      "&:hover fieldset": {
        borderColor: "#28334a",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#d29723",
      },
    },
  },
})(TextField)

const Loading = withStyles({
  root: {
    color: "#d29723",
  },
})(CircularProgress)

const CustomSignIn = props => {
  const { authState, onStateChange, location } = props
  const [showPass, setShowPass] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    username: "",
    password: "",
    email: "",
    authCode: "",
    phone_number: "",
    code: "",
    new_password: "",
    company_name: "",
  })
  const [success, setSuccess] = useState(false)
  const [msg, setMsg] = useState("")
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)

  // Getting Form Data
  const handleInputChange = (value, prop) => {
    setFormData({
      ...formData,
      [prop]: value,
    })
  }

  useEffect(() => {
    console.log("State changed: ", authState)
    if (error.length) setError("")
    if (success) setSuccess(false)
    if (authState === "signIn")
      setFormData({
        name: "",
        username: "",
        password: "",
        email: "",
        authCode: "",
        phone_number: "",
        code: "",
        new_password: "",
        company_name: "",
      })
    if (authState === "signUp")
      setFormData({
        name: "",
        username: "",
        password: "",
        email: "",
        authCode: "",
        phone_number: "",
        code: "",
        new_password: "",
        company_name: "",
      })
  }, [authState])

  const sChange = event => {
    onStateChange(event)
    setError("")
    setSuccess(false)
  }

  const signInClick = async () => {
    setError("")
    setSuccess(false)

    try {
      console.log("Now signing in... data: ", formData)
      if (!formData.username.length)
        return setError("The username cannot be empty!")
      if (!formData.password.length)
        return setError("The password cannot be empty!")

      setLoading(true)
      const res = await Auth.signIn(formData.username, formData.password)
      console.log("Signed in with: ", res)
      setSuccess(true)
      setLoading(false)

      navigate("/dashboard")
    } catch (error) {
      console.log(error)
      setError("The username or password is incorrect. Please try again.")
      setLoading(false)
    }
  }
  const signUpClick = async () => {
    setError("")
    setSuccess(false)
    console.log("Inside sign up...")

    try {
      const { username, password, phone_number, name, company_name } = formData

      if (!name.length) return setError("Name cannot be empty!")
      if (!username.length) return setError("Email cannot be empty!")
      console.log(username)
      if (!company_name.length) return setError("Company Name cannot be empty!")
      if (!phone_number.length) return setError("Phone number cannot be empty!")
      if (!password.length) return setError("The password cannot be empty!")

      console.log("Now signing up..., data: ", formData)
      setLoading(true)
      const res = await Auth.signUp({
        username,
        password,
        attributes: {
          email: username,
          phone_number: "+1" + phone_number,
          name: name,
          "custom:company_name": company_name,
        },
      })
      console.log("Signed Up with: ", res)

      setSuccess(true)
      setLoading(false)
      onStateChange("confirmSignUp")
    } catch (error) {
      setError(
        "The information provided is not correct. Make sure the phone number is a valid one with no spaces or hypens. Make sure the password is min six characters."
      )
      setLoading(false)
    }
  }
  const confirmSignUpClick = async () => {
    setError("")
    setSuccess(false)

    console.log("Inside confirm sign up...")
    try {
      const { username, authCode, company_name, password } = formData
      if (!username.length) return setError("The username cannot be empty!")
      if (!authCode.length) return setError("The code cannot be empty!")

      console.log("Now Confirming Sign Up...")
      setLoading(true)
      const resConfirmSignUp = await Auth.confirmSignUp(username, authCode)
      console.log("Confirmed Sign Up with: ", resConfirmSignUp)

      setSuccess(true)
      setLoading(false)
      onStateChange("signIn")
    } catch (error) {
      setError("The Auth Code provided is incorrect.")
      console.log("SignUp Error: ", error)
      setLoading(false)
    }
  }
  const forgotPasswordClick = async () => {
    setError("")
    setSuccess(false)

    try {
      const { username } = formData
      if (!username.length) return setError("The username cannot be empty!")

      setLoading(true)
      await Auth.forgotPassword(username)
      setSuccess(true)
      setLoading(false)
      onStateChange("resetPassword")
    } catch (error) {
      setError("The email entered is not in our records.")
      setLoading(false)
    }
  }
  const resetPasswordClick = async () => {
    setError("")
    setLoading(false)
    setSuccess(false)

    try {
      const { username, code, new_password } = formData
      if (!code.length) return setError("The code cannot be empty!")
      if (!new_password.length) return setError("The password cannot be empty!")
      if (!username.length)
        return setError(
          "Something went wrong! Please exit and retry. If issues still persist you can click on the floating button on bottom right to reach us!"
        )
      setLoading(true)
      await Auth.forgotPasswordSubmit(username, code, new_password)
      setSuccess(true)
      setLoading(false)
      onStateChange("signIn")
    } catch (error) {
      setError(
        "The code entered is incorrect or the password does not meet the six character requirement."
      )
      setLoading(false)
    }
  }

  const resendCodeClick = async () => {
    setError("")
    setSuccess(false)

    try {
      const { username } = formData
      if (!username.length)
        return setError(
          "Something went wrong! Please exit and retry. If issues still persist you can click on the floating button on bottom right to reach us!"
        )
      setLoading(true)
      await Auth.resendSignUp(username)
      setSuccess(true)
      setLoading(false)
      setMsg("Resent code to your email!")
    } catch (error) {
      setError("Could not resend code. Please try again!")
      setLoading(false)
    }
  }

  const resendForgotPassCodeClick = async () => {
    setError("")
    setSuccess(false)

    try {
      const { username } = formData
      if (!username.length)
        return setError(
          "Something went wrong! Please exit and retry. If issues still persist you can click on the floating button on bottom right to reach us!"
        )
      setLoading(true)
      await Auth.forgotPassword(username)
      setSuccess(true)
      setLoading(false)
      setMsg("Resent code to your email!")
    } catch (error) {
      setError("Could not resend code. Please try again!")
      setLoading(false)
    }
  }

  // Enter Key works as Button Click - deactivating for now
  const keyPressed = event => {
    console.log(event)
    if (event.key === "Enter") {
      signInClick()
    }
  }

  useEffect(() => {
    console.log("On SignIn Mount, checking location prop: ", location)
    if (location?.state?.checkAuthStatus) {
      ;(async function () {
        try {
          const user = await Auth.currentAuthenticatedUser()
          console.log("On SignIn Mount, retrived user: ", user)
          navigate("/dashboard/")
        } catch (e) {
          console.log("On SignIn Mount, error: ", e)
        }
      })()
    }
  }, [])

  return (
    <>
      <div className={styles.container}>
        {authState === "signIn" && (
          <div className={styles.loginCard}>
            <div className={styles.login}>
              <div className={styles.header}>
                <span className={styles.highlight}>Welcome</span> Back
              </div>
              <div>
                <EmailField
                  label="Email"
                  type="email"
                  variant="outlined"
                  className={styles.email}
                  value={formData.username}
                  onChange={e =>
                    handleInputChange(e?.target?.value || "", "username")
                  }
                  onKeyDown={keyPressed}
                  disabled={loading}
                />
              </div>
              <div>
                <EmailField
                  disabled={loading}
                  label="Password"
                  variant="outlined"
                  type={showPass ? "text" : "password"}
                  className={styles.password}
                  data-prop={"password"}
                  value={formData.password}
                  onChange={e =>
                    handleInputChange(e?.target?.value || "", "password")
                  }
                  onKeyDown={keyPressed}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => {
                            const newState = !showPass
                            setShowPass(newState)
                          }}
                        >
                          {showPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div
                className={styles.forgot}
                onClick={() => onStateChange("forgotPassword")}
              >
                Forgot password?
              </div>
              <div style={{ margin: "12px 0px", width: "100%" }}>
                {success && (
                  <Alert severity="success">
                    <strong>Success: </strong> Succesfully signed in!
                  </Alert>
                )}
                {error.length !== 0 && (
                  <Alert severity="error">
                    <strong>Error: </strong>
                    {error}
                  </Alert>
                )}
              </div>
              <div className={styles.box}>
                <div className={styles.button}>
                  {loading ? (
                    <Loading size={25} thickness={5} />
                  ) : (
                    <div
                      className={styles.button_text}
                      onClick={() => signInClick()}
                    >
                      Sign In
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.getStartedLink}>
                Don’t have an account?{" "}
                <span
                  className={styles.signup}
                  onClick={() => sChange("signUp")}
                >
                  Sign Up
                </span>
              </div>
            </div>
          </div>
        )}
        {authState === "signUp" && (
          <div className={styles.loginCard}>
            <div className={styles.login}>
              <div className={styles.header}>
                Get
                <span className={styles.highlight}> Started </span>With
                <span className={styles.highlight}> FinSimpl</span>
              </div>
              <div>
                <EmailField
                  label="Name"
                  type="text"
                  variant="outlined"
                  className={styles.email}
                  value={formData.name}
                  onChange={e =>
                    handleInputChange(e?.target?.value || "", "name")
                  }
                  onKeyDown={keyPressed}
                  disabled={loading}
                />
              </div>
              <div>
                <EmailField
                  label="Email"
                  type="email"
                  variant="outlined"
                  className={styles.email}
                  value={formData.username}
                  onChange={e =>
                    handleInputChange(e?.target?.value || "", "username")
                  }
                  onKeyDown={keyPressed}
                  disabled={loading}
                />
              </div>
              <div>
                <EmailField
                  label="Company Name"
                  type="text"
                  variant="outlined"
                  className={styles.email}
                  value={formData.company_name}
                  onChange={e =>
                    handleInputChange(e?.target?.value || "", "company_name")
                  }
                  onKeyDown={keyPressed}
                  disabled={loading}
                />
              </div>
              <div>
                <EmailField
                  label="Phone Number"
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                  variant="outlined"
                  className={styles.email}
                  value={formData.phone_number}
                  onChange={e =>
                    handleInputChange(e?.target?.value || "", "phone_number")
                  }
                  onKeyDown={keyPressed}
                  disabled={loading}
                />
              </div>
              <div>
                <EmailField
                  label="Password"
                  disabled={loading}
                  variant="outlined"
                  type={showPass ? "text" : "password"}
                  className={styles.password}
                  data-prop={"password"}
                  value={formData.password}
                  onChange={e =>
                    handleInputChange(e?.target?.value || "", "password")
                  }
                  onKeyDown={keyPressed}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => {
                            const newState = !showPass
                            setShowPass(newState)
                          }}
                        >
                          {showPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ margin: "12px 0px", width: "100%" }}>
                {success && (
                  <Alert severity="success">
                    <strong>Success: </strong> Succesfully signed up!
                  </Alert>
                )}
                {error.length !== 0 && (
                  <Alert severity="error">
                    <strong>Error: </strong>
                    {error}
                  </Alert>
                )}
              </div>
              <div className={styles.box}>
                <div className={styles.button}>
                  {loading ? (
                    <Loading size={25} thickness={5} />
                  ) : (
                    <div
                      className={styles.button_text}
                      onClick={() => signUpClick()}
                    >
                      Sign Up
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.getStartedLink}>
                Already have an account?{" "}
                <span
                  className={styles.signup}
                  onClick={() => sChange("signIn")}
                >
                  Sign In
                </span>
              </div>
            </div>
          </div>
        )}
        {authState === "confirmSignUp" && (
          <div className={styles.loginCard}>
            <div className={styles.login}>
              <div className={styles.header}>Confirm Sign Up</div>
              <div>
                <EmailField
                  label="Code"
                  variant="outlined"
                  className={styles.email}
                  value={formData.authCode}
                  onChange={e =>
                    handleInputChange(e?.target?.value || "", "authCode")
                  }
                  onKeyDown={keyPressed}
                  disabled={loading}
                />
              </div>
              <div style={{ margin: "12px 0px", width: "100%" }}>
                {success && (
                  <Alert severity="success">
                    <strong>Success: </strong>
                    {msg.length ? msg : "Succesfully signed up!"}
                  </Alert>
                )}
                {error.length !== 0 && (
                  <Alert severity="error">
                    <strong>Error: </strong>
                    {error}
                  </Alert>
                )}
              </div>
              <div className={styles.buttonContainer}>
                <div className={styles.resendBox}>
                  <div className={[styles.box, styles.resendField]}>
                    <div className={styles.button}>
                      {loading ? (
                        <Loading size={25} thickness={5} />
                      ) : (
                        <div
                          className={styles.button_text}
                          onClick={() => resendCodeClick()}
                        >
                          Resend Code
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.submitBox}>
                  <div className={[styles.box, styles.submitField]}>
                    <div className={styles.button}>
                      {loading ? (
                        <Loading size={25} thickness={5} />
                      ) : (
                        <div
                          className={styles.button_text}
                          onClick={() => confirmSignUpClick()}
                        >
                          Confirm Sign Up
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {authState === "forgotPassword" && (
          <div className={styles.loginCard}>
            <div className={styles.login}>
              <div className={styles.header}>Reset your Password</div>
              <div>
                <EmailField
                  label="Email"
                  type="email"
                  variant="outlined"
                  className={styles.email}
                  value={formData.username}
                  onChange={e =>
                    handleInputChange(e?.target?.value || "", "username")
                  }
                  onKeyDown={keyPressed}
                  disabled={loading}
                />
              </div>
              <div className={styles.signup} onClick={() => sChange("signIn")}>
                Go Back
              </div>
              <div style={{ margin: "12px 0px", width: "100%" }}>
                {success && (
                  <Alert severity="success">
                    <strong>Success: </strong> Valid email address entered!
                  </Alert>
                )}
                {error.length !== 0 && (
                  <Alert severity="error">
                    <strong>Error: </strong>
                    {error}
                  </Alert>
                )}
              </div>
              <div className={styles.box}>
                <div className={styles.button}>
                  {loading ? (
                    <Loading size={25} thickness={5} />
                  ) : (
                    <div
                      className={styles.button_text}
                      onClick={() => forgotPasswordClick()}
                    >
                      Send Code
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        {authState === "resetPassword" && (
          <div className={styles.loginCard}>
            <div className={styles.login}>
              <div className={styles.header}>Reset your Password</div>
              <div>
                <EmailField
                  label="Code"
                  variant="outlined"
                  className={styles.email}
                  value={formData.code}
                  onChange={e =>
                    handleInputChange(e?.target?.value || "", "code")
                  }
                  onKeyDown={keyPressed}
                  disabled={loading}
                />
              </div>
              <div>
                <EmailField
                  label="New Password"
                  disabled={loading}
                  variant="outlined"
                  type={showPass ? "text" : "password"}
                  className={styles.password}
                  value={formData.new_password}
                  onChange={e =>
                    handleInputChange(e?.target?.value || "", "new_password")
                  }
                  onKeyDown={keyPressed}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onMouseDown={e => e.preventDefault()}
                          onClick={() => {
                            const newState = !showPass
                            setShowPass(newState)
                          }}
                        >
                          {showPass ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
              <div style={{ margin: "12px 0px", width: "100%" }}>
                {success && (
                  <Alert severity="success">
                    <strong>Success: </strong>
                    {msg.length ? msg : "Succesfully reset password!"}
                  </Alert>
                )}
                {error.length !== 0 && (
                  <Alert severity="error">
                    <strong>Error: </strong>
                    {error}
                  </Alert>
                )}
              </div>
              <div className={styles.buttonContainer}>
                <div className={styles.resendBox}>
                  <div className={[styles.box, styles.resendField]}>
                    <div className={styles.button}>
                      {loading ? (
                        <Loading size={25} thickness={5} />
                      ) : (
                        <div
                          className={styles.button_text}
                          onClick={() => resendForgotPassCodeClick()}
                        >
                          Resend Code
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.submitBox}>
                  <div className={[styles.box, styles.submitField]}>
                    <div className={styles.button}>
                      {loading ? (
                        <Loading size={25} thickness={5} />
                      ) : (
                        <div
                          className={styles.button_text}
                          onClick={() => resetPasswordClick()}
                        >
                          Save
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={styles.fineprint}>
        By signing up, you agree to FinSimpl’s{" "}
        <Link to="/legal/">Terms of Use and Privacy Policy</Link>
      </div>
    </>
  )
}

export default CustomSignIn
