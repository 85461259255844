import React from "react"

import Layout from "../components/layout"
import Menu from "../components/menu"
import Footer from "../components/footer"
import Login from "../components/login"

import {
  Authenticator,
  ConfirmSignUp,
  ForgotPassword,
  SignUp,
  SignIn,
  Greetings,
} from "aws-amplify-react"
import config from "../aws-exports"

const LoginPage = props => (
  <Layout type={1}>
    <Authenticator
      hide={[SignIn, SignUp, ConfirmSignUp, ForgotPassword, Greetings]}
      amplifyConfig={config}
    >
      <Menu type={2} btype={2} />
      <Login location={props.location} />
      <Footer type={1} />
    </Authenticator>
  </Layout>
)

export default LoginPage
